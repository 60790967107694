<template>
  <EuiDialog :open="true" @onClose="$emit('cancel')">
    <template v-slot:header>{{ title }}</template>
    <slot />
    <template v-slot:footer>
      <EuiButton v-if="cancelText" @click="$emit('cancel')">{{ cancelText }}</EuiButton>
      <EuiButton v-else  @click="$emit('cancel')">{{ $t('button.cancel') }}</EuiButton>
      <EuiButton v-if="confirmText" variant="raised" :class="classes" @click="$emit('confirm')">{{ confirmText }}</EuiButton>
    </template>
  </EuiDialog>
</template>

<script>
export default {
  name: 'confirmationDialog',
  /**
   * The properties that can be used.
   */
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'primary',
    },
    confirmText: {
      type: String,
      required: false,
    },
    cancelText: {
      type: String,
      required: false,
    },
  },
  computed: {
    classes() {
      return {
        'bg-alert': this.type === 'danger',
      };
    },
  },
};
</script>
